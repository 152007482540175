
  import { defineComponent, onMounted, onUnmounted } from "vue";
  import { useStore } from "vuex";
  import { Actions } from "../../store/enums/StoreEnums";
  import { getIllustrationsPath } from "../../core/helpers/assets";
  import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
  import { reinitializeComponents } from "@/core/plugins/keenthemes";
  import { reactive } from "vue";
  import { ref } from "vue";
  import { getTransactionsRequest } from "@/latipay/apis/services/TransactionsService";
  import { DateTimeUtils } from "@/latipay/utils/DateTimeUtils";
  import { ElMessage } from "element-plus";
  import useClipboard from 'vue-clipboard3'

  export default defineComponent({
    name: "transactions",
    components: {},
    setup() {
      const store = useStore();


      const name = ref<string | null>(null);
      const email = ref<string | null>(null);
      const status = ref<string | null>(null);
      const sort = ref<string | null>(null);
      const pageNumber = ref(1);
      const pageSize = ref(20);

      const loading = ref(false);

      const { toClipboard } = useClipboard();
      const formInline = reactive({

        id: "",
        merchantId: "",
        walletId: "",
        type: "",
        gateway: "",
        channel: "",
        currencyFrom: "",
        currencyTo: "",
        status: ""

      });

      const {
        transactionsRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        getTransactions
      } = getTransactionsRequest();


      const getData = async () => {
        loading.value = true;

        // await updateQueries();
        await getTransactions(
          formInline.id,
          formInline.merchantId,
          formInline.walletId,
          formInline.type,
          formInline.gateway,
          formInline.channel,
          formInline.currencyFrom,
          formInline.currencyTo,
          formInline.status,
          sort.value,
          pageNumber.value,
          pageSize.value
        );
        reinitializeComponents();

        loading.value = false;

        console.log(transactionsRef);
      };


      onMounted(async () => {
        // setCurrentPageTitle("Users");
        await getData();
      });

      const updatePageSize = async (val: number) => {
        console.log(`${val} items per page`);
        pageSize.value = val;
        await getData();
      };
      const updatePageNumber = async (val: number) => {
        console.log(`current page: ${val}`);
        pageNumber.value = val;
        await getData();
      };



      const copySlug = async (slug: string) => {
        try {
          await toClipboard(slug);
          ElMessage({
            showClose: true,
            message: 'Copy successfully!',
            type: "success"
          });
        } catch (e) {
          console.error(e)
        }
      };


      return {
        getIllustrationsPath,
        formInline,
        getData,
        loading,
        updatePageSize,
        updatePageNumber,
        transactionsRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        DateTimeUtils,
        pageSize,
        pageNumber,
        copySlug

      };

    },
    methods: {
      async resetFormFields() {

        (this.$refs["searchForm"] as any).resetFields();

        await this.getData();

      },
      async onSubmitSearchForm() {
        await this.getData();
      }

    }
  });
